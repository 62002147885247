import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import generateLink from '../../utils/generateLink'
import Seo from '../../components/seo'
import SecondaryHeading from '../../components/SecondaryHeading'
import SectionDoubleColumn from '../../components/SectionDoubleColumn'
import SideCol from '../../components/SectionDoubleColumn/SideCol'
import ReactMarkdown from 'react-markdown'
import Image from '../../components/image'
import { Carousel } from 'react-responsive-carousel'

const Services = ({ langKey, location }) => {
  const { site } = useStaticQuery(
    graphql` query {
      site {
        siteMetadata {
          languages {
            defaultLangKey
            langs
          } 
          pagesData {
            services {
              sideSection {
                firstBlockLinks {
                  text {
                    de
                    en
                    it
                  }
                  path
                }
                secondBlock {
                  de
                  en
                  it
                }
              }
              pageTitle {
                de
                en
                it
              }
              mainSection {
                firstSection{
                  title {
                    de
                    en
                    it
                  }
                  paragraphs {
                    de
                    en
                    it
                  }
                } 
                secondSection {
                  title {
                    de
                    en
                    it
                  }
                  paragraphs {
                    de
                    en
                    it
                  }
                  points {
                    title {
                      it,
                      en,
                      de
                    }
                    text {
                      de
                      en
                      it
                    }
                    id
                    images
                  }
                }
              }
            }
          }
        }
      }
    }
      `
  )
  const {
    pageTitle,
    mainSection,
    sideSection
  } = site.siteMetadata.pagesData.services

  const mainCol = (
    <div>
      <h2 className='text-3xl underline font-bold mb-16'>{mainSection.firstSection.title[langKey]}</h2>
      <div className='mt-4'>
        {
          mainSection.firstSection.paragraphs.map((el, i) => (<div key={`paragraph1_${i}`}><ReactMarkdown source={el[langKey]} /></div>))
        }
      </div>
      <div className='mt-10'>
        <h2 className='text-2xl underline font-bold'>{mainSection.secondSection.title[langKey]}</h2>
        <div className='mt-4'>
          {
            mainSection.secondSection.paragraphs.map((el, i) => (<div key={`paragraph2_${i}`}><ReactMarkdown source={el[langKey]} /></div>))
          }
        </div>
        <div className='mt-4'>

          {
            mainSection.secondSection.points.map((el, i) => (
              <div id={el.id} className='my-12' key={`point_${i}`}>
                <h3 className='text-2xl underline font-bold text-green-gefa mt-10 mb-6'>{el.title[langKey]}</h3>
                <ReactMarkdown source={el.text[langKey]} className='mb-6' />
                {
                  el.images && el.images.length > 0 && (
                    el.images.length === 1 ? (
                      <Image className='my-10' imgRef={el.images[0]} />
                    ) : (
                      <Carousel showThumbs={false} showStatus={false} showIndicators={false} autoPlay interval={4000} infiniteLoop>
                        {
                          el.images.map((im, j) => <Image key={`c_mi_${j}`} imgRef={im} />)
                        }
                      </Carousel>
                    )
                  )
                }
              </div>
            ))
          }

        </div>
      </div>
    </div>
  )

  const linksForSide = sideSection.firstBlockLinks.map((el) => ({ path: generateLink(langKey, '/services/' + el.path), text: el.text[langKey] }))
  const sideCol = <SideCol links={linksForSide} textBlock={sideSection.secondBlock[langKey]} />

  return (
    <>
      <Seo title={pageTitle[langKey]} urlPath={location.pathname} />
      <SecondaryHeading title={pageTitle[langKey]} />
      <SectionDoubleColumn mainCol={mainCol} sideCol={sideCol} />
    </>
  )
}

export default Services
